<template>
  <div class="stay">
    <div class="_title">酒店预订</div>
      <div class="btns">
      <div class="btn"  @click="jump('/register')">
       注册
      </div>
      <div class="btn" @click="jump('/login')">
       登录
      </div>
    </div>
    <div class="desc">
      <div class="wrap">
        <p>
          <span>会议地点：</span>
          三亚市三亚湾天涯海角红树林国际会展中心三层B厅
        </p>
        <p>
          <span>地 址：</span>
          海南省三亚市天涯区凤凰路155号
        </p>
      </div>
      <div class="wrap">
        <p class="_blue font-weight-bold">
           <span>会议住宿：</span>
          <!-- TODO 登录 link -->
          12月份为海南三亚的旅游旺季，房源比较紧张。<br>
          为保证参会者的住宿，会务组指定合作酒店为： 三亚湾红树林度假世界  。<br>
          酒店距离会议中心距离 50米
          本网站预定，可享受会议协约价格。预定请<a><span style="color: red">点击预定</span></a>
          <br>
          酒店房间数量紧张，请代表尽早预定住宿。代表也可通过其他网络平台自行预定酒店。
        </p>
      </div>
          <div class="wrap">
        <p>
          <span>会议用酒店房型及价格：</span>
       
        </p>
        <p>
          1、大床房  含 单早  480元/人；
        </p>
        <p>2、大床房  含 双早  530元/人；</p>
        <p>3、标间    含 单早  480元/人;</p>
        <p>4、标间    含 双早  530元/人;</p>
        <p>（有公务卡的参会代表，请尽量使用公务卡支付。）</p>
      </div>
      <div class="wrap">
        <p>
          <span>交通路线：</span>
        </p>
        <p>1. 三亚凤凰机场——红树林国际会议中心  8.9公里  打车约 20元。</p>
        <p>2. 三亚站——红树林国际会议中心   1.6公里  打车约10元。</p>

      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style lang="less" scoped>
.stay {
  padding: 0 20px;
  padding-bottom: 150px;

  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  ._blue{
      color: #337FE5;
     
  }
 
  .wrap {
    margin-top: 50px;
  }
  p {
    margin-bottom: 4px !important;
    span {
      font-weight: bold;
    }
  }
}
</style>
